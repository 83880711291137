import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import InsideCard from './InsideCard';
import OutsideCard from './OutsideCard';

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}));

const App : React.FC = () => {
    const classes = useStyles();
    const [pageVisible, setPageVisible] = useState(document.visibilityState === 'visible');

    useEffect(() => {
        const listener = () => {
            setPageVisible(document.visibilityState === 'visible');
        };

        document.addEventListener('visibilitychange', listener);
        return () => document.removeEventListener('visibilitychange', listener);
    }, []);

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">Climate</Typography>
                </Toolbar>
            </AppBar>

            <Container className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <OutsideCard pageVisible={pageVisible}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InsideCard pageVisible={pageVisible}/>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default App;
