import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import OutsideIcon from '@material-ui/icons/WbSunny';
import React, {useEffect, useState} from 'react';

type Measurement = {
    airTemperature : number;
    apparentTemperature : number;
    airPressure : number;
    humidity : number;
    rainfall : number;
};

type Props = {
    pageVisible : boolean;
};

const OutsideCard : React.FC<Props> = ({pageVisible} : Props) => {
    const [measurement, setMeasurement] = useState<Measurement | null>(null);

    useEffect(() => {
        if (!pageVisible) {
            return;
        }

        let canceled = false;

        const loadMeasurement = async () => {
            const response = await fetch(new URL('/outside', process.env.REACT_APP_API_ENDPOINT).toString());

            if (!response.ok) {
                return;
            }

            const measurement = await response.json();

            if (!canceled) {
                setMeasurement(measurement);
            }
        };

        loadMeasurement();

        const interval = window.setInterval(loadMeasurement, 1000 * 60 * 5);

        return () => {
            canceled = true;
            window.clearInterval(interval);
        };
    }, [pageVisible]);

    return (
        <Card>
            <CardHeader
                avatar={<OutsideIcon/>}
                title="Outside"
            />
            <CardContent>
                {!measurement ? (
                    <CircularProgress/>
                ) : (
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Air Temperature"
                                secondary={`${measurement.airTemperature}°C`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Apparent Temperature"
                                secondary={`${measurement.apparentTemperature}°C`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Humidity"
                                secondary={`${measurement.humidity}%`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Air Pressure"
                                secondary={`${measurement.airPressure} hPa`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Rainfall (last 10 minutes)"
                                secondary={`${measurement.rainfall} mm`}
                            />
                        </ListItem>
                    </List>
                )}
            </CardContent>
        </Card>
    );
};

export default OutsideCard;
